<template>
	<div class="app-container">
		<p style="text-align: center; margin: 0 0 20px">{{translate('USER_ROLE.FOR_USER')}}<span style="color: red">{{userId}} - {{user.nickname}}</span>{{translate("USER_ROLE.ASSIGN_ROLE")}}</p>
		<div style="text-align: center">
			<el-transfer
				style="text-align: left; display: inline-block;"
				:titles="[translate('USER_ROLE.ASSIGN_ROLE'), translate('USER_ROLE.UNASSIGNED_ROLE')]"
				filterable
				:filter-method="filterMethod"
				:placeholder="translate('FROM.PLACEHOLDER', {param: 'COMMON.KEYWORD'})"
				v-model="value"
				:data="data"></el-transfer>
			<el-row style="padding-top: 20px;">
				<el-button type="primary" style="width: 300px;" @click="updateUserRole">{{translate("FROM.SUBMIT")}}</el-button>
			</el-row>
		</div>

	</div>
</template>

<script>
	import {UserApi} from '@/api';
	export default {
		name: "Index",
		data(){
			return{
				userId: 0,
				user:{},
				value: [],
				data: [],
				userRoles:{}
			}
		},
		async mounted(){
			this.userId = this.$route.params.userId;
			this.user = (await UserApi.user.detail(this.userId)).data || {};
			this.listUserRole();
		},
		methods: {
			async listUserRole(){
				this.userRoles = (await UserApi.user.listUserRole(this.userId)).data || {};
				let hasRoles = (this.userRoles.hasRoles || []).map(item => {
					return{
						label: item.name,
						key: item.roleId
					}
				});
				let notHasRoles = (this.userRoles.notHasRoles || []).map(item => {
					return{
						label: item.name,
						key: item.roleId
					}
				});
				let hasRoleIds = (this.userRoles.hasRoles || []).map(item => item.roleId);
				this.data = [];
				this.value = [];
				this.data.push(...hasRoles, ...notHasRoles);
				this.value.push(...hasRoleIds);
			},
			async updateUserRole(){
				let result = await UserApi.user.updateUserRole(this.userId, this.value);
				if (result.success){
					this.$message.success(this.translate('COMMON.OPERATION_SUCCESS'));
					this.listUserRole();
				}
			},
			filterMethod(query, item) {
				return item.label.indexOf(query) > -1;
			}
		}
	}
</script>

<style scoped>
</style>
